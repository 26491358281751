<template>
  <div class="shop-feedback">
    <form @submit.prevent="feedbackCreate" class="form">
      <h3 class="form__title">Обратная связь</h3>
      <div class="form__row">
        <div class="form__col-6">
          <InputComponent
            v-model="form.title.value"
            title="Ваше имя"
            placeholder="Введите ваше имя"
            :subtitle="form.title.message"
            :error="form.title.error"
          />
        </div>
        <div class="form__col-6">
          <InputComponent
            v-model="form.theme.value"
            title="Тема обращения"
            placeholder="Тема обращения"
            :subtitle="form.theme.message"
            :error="form.theme.error"
          />
        </div>
        <div class="form__col-12">
          <TextareaComponent
            v-model="form.text.value"
            title="Текст обращения"
            placeholder="Текст обращения"
            :subtitle="form.text.message"
            :error="form.text.error"
          />
        </div>
        <div class="form__col-12">
          <button type="submit" class="btn btn--lg btn--main-inv">
            <FileIconComponent v-if="loading" name="loading" />
            <template v-else>Отправить</template>
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import InputComponent from "components/inputs/InputComponent.vue";
import TextareaComponent from "components/inputs/TextareaComponent.vue";
import FEEDBACK_CREATE from "@/graphql/mutations/FeedbackCreate.graphql";
import LoginModal from "components/modals/components/LoginModal.vue";
import FileIconComponent from "components/FileIconComponent.vue";

export default {
  name: "ShopFeedbackComponent",
  data() {
    return {
      loading: false,
      form: {
        title: {
          value: undefined,
          error: undefined,
          message: undefined,
        },
        theme: {
          value: undefined,
          error: undefined,
          message: undefined,
        },
        text: {
          value: undefined,
          error: undefined,
          message: undefined,
        },
      },
    };
  },
  computed: {
    isRegistered() {
      return !!this.$store.state.apollo_token;
    },
  },
  methods: {
    feedbackCreate() {
      if (this.isRegistered) {
        if (!this.loading) {
          this.loading = true;
          this.resetErrors("form");
          this.$apollo
            .mutate({
              mutation: FEEDBACK_CREATE,
              variables: {
                shop_id: parseInt(this.$route.params.id),
                title: this.form.title.value,
                theme: this.form.theme.value,
                text: this.form.text.value,
              },
              context: {
                headers: {
                  Authorization: "Bearer " + this.$store.state.apollo_token,
                },
              },
            })
            .then(({ data }) => {
              if (data && data.FeedbackCreate) {
                this.$notify({
                  title: "Отправлено",
                  text: data.FeedbackCreate.message,
                  duration: 5000,
                  speed: 200,
                  type: "success",
                });
              }
              this.loading = false;
            })
            .catch(({ graphQLErrors }) => {
              this.loading = false;
              this.parseGqlErrors(graphQLErrors);
            });
        }
      } else {
        this.$store.state._modals.push({
          component: LoginModal,
          options: {
            routeAfterLogin: {
              name: this.$route.name,
              params: this.$route.params,
              hash: this.$route.hash,
            },
          },
        });
      }
    },
    parseGqlErrors(graphQLErrors) {
      graphQLErrors.forEach((err) => {
        if (err.extensions.category === "validation") {
          Object.keys(err.extensions.validation).forEach((key) => {
            if (this.form[key]) {
              this.form[key].error = err.extensions.validation[key][0];
            }
          });
        }
      });
    },
    resetErrors(form) {
      Object.keys(this[form]).forEach((key) => {
        this[form][key].error = undefined;
      });
    },
  },
  components: { FileIconComponent, InputComponent, TextareaComponent },
};
</script>

<style lang="stylus">
.shop-feedback {
  display flex
  flex-direction column
  align-items center
  padding 0 15px

  .form {
    padding 32px
    border none
    width 100%
    max-width var(--main_width)
    +below(600px) {
      padding 0
    }

    &__title {
      font-size 1.5em
      line-height 133%
      margin 0
    }

    .btn {
      width 100%
      max-width 260px
      justify-content center
    }

    textarea {
      height 176px
    }
  }

  .form__col-6 {
    +below(680px) {
      gap 16px
      flex-basis calc(100% - 16px)
    }
  }
}
</style>
