<template>
  <div class="reviews">
    <div class="reviews__container">
      <h3 class="reviews__title">Отзывы</h3>
      <div class="reviews__body">
        <ul v-if="reviews && reviews.length" class="reviews__list">
          <li v-for="(review, i) in showAllReviews ? reviews : reviews.slice(0, 5)" :key="i">
            <ReviewComponent :data="review" />
          </li>
          <li v-if="reviews.length > 5">
            <button type="button" class="btn btn--sm btn--main" @click="showAllReviews = !showAllReviews">
              <template v-if="showAllReviews">Скрыть</template>
              <template v-else>Показать все</template>
            </button>
          </li>
        </ul>
        <ReviewsStatComponent @show="showReviewCreateModal" :reviews="reviews" />
      </div>
    </div>
  </div>
</template>

<script>
import ReviewComponent from "components/reviews/ReviewComponent.vue";
import ReviewsStatComponent from "components/reviews/ReviewsStatComponent.vue";
import ReviewCreateModal from "components/modals/components/ReviewCreateModal.vue";
import LoginModal from "components/modals/components/LoginModal.vue";

export default {
  name: "ReviewsComponent",
  props: {
    reviews: Array,
    data: Object,
    reviewable_type: String,
  },
  data() {
    return {
      showAllReviews: false,
    };
  },
  computed: {
    isRegistered() {
      return !!this.$store.state.apollo_token || this.$store.state.auth.user;
    },
  },
  methods: {
    showReviewCreateModal() {
      if (this.isRegistered) {
        this.$store.state._modals.push({
          component: ReviewCreateModal,
          options: {
            data: this.data,
            reviewable_type: this.reviewable_type,
          },
        });
      } else {
        this.$store.state._modals.push({
          component: LoginModal,
          options: {
            routeAfterLogin: {
              name: this.$route.name,
              params: this.$route.params,
              hash: this.$route.hash,
            },
          },
        });
      }
    },
  },
  components: { ReviewsStatComponent, ReviewComponent },
};
</script>

<style lang="stylus">
.reviews {
  display flex
  justify-content center
  padding 0 15px

  &__container {
    max-width var(--main_width)
    width 100%
    display grid
    gap 24px
  }

  &__title {
    font-weight: normal;
    font-size: 1.5em;
    line-height: 32px;
    display: flex;
    align-items: center;
    color: var(--body-color);
    margin 0
  }

  &__body {
    display flex
    gap 64px
    align-items flex-start
    justify-content flex-start
    +below(860px) {
      gap 0
      flex-direction column-reverse
    }
  }

  .reviews-stat {
    max-width 515px
    width 100%
    +below(860px) {
      max-width 100%
      margin-bottom 40px
    }
  }

  &__list {
    max-width 715px
    width 100%
    display grid
    grid-gap 32px
    grid-area item-list
    +below(860px) {
      max-width 100%
    }
  }
}
</style>
