<template>
  <section v-if="communications && communications.length" class="shop-contacts">
    <div class="shop-contacts__container">
      <h3 class="title title__left">Контакты</h3>
      <div class="shop-contacts__inner">
        <div class="shop-contacts__map">
          <yandex-map :settings="settings" :coords="coords" v-if="mapReady" zoom="16">
            <ymap-marker marker-id="0" :coords="coords" :icon="icon" />
          </yandex-map>
        </div>
        <div class="shop-contacts__info">
          <div class="shop-contacts__list">
            <div v-for="(item, i) in communications" :key="i" class="shop-contacts__item">
              <span class="shop-contacts__item-title"> {{ item.value }} </span>
              <a
                :href="`mailto:${item.link}`"
                v-if="item.type.code === $store.state._types.EMAIL"
                class="shop-contacts__item-description"
                rel="nofollow"
              >
                {{ item.link }}
              </a>
              <a
                :href="`tel:${item.link}`"
                v-else-if="item.type.code === $store.state._types.PHONE"
                class="shop-contacts__item-description"
                rel="nofollow"
              >
                {{ item.link }}
              </a>
              <span v-else class="shop-contacts__item-description">
                {{ item.link }}
              </span>
            </div>
            <!--            <div class="shop-contacts__item">-->
            <!--              <span class="shop-contacts__item-title"> График работы </span>-->
            <!--              <span class="shop-contacts__item-description">-->
            <!--                Пн-Пт 10:00 - 22:00 <br />-->
            <!--                Сб-Вс 11:00 - 27:00-->
            <!--              </span>-->
            <!--            </div>-->
          </div>
          <SocialsComponent
            :socials="communications.filter((item) => item.type.code === this.$store.state._types.SOCIAL)"
            class="shop-contacts__socials"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { loadYmap, yandexMap, ymapMarker } from "vue-yandex-maps";
import SocialsComponent from "components/SocialsComponent.vue";

export default {
  name: "ContactsPage",
  props: {
    communications: Array,
  },
  data() {
    return {
      mapReady: false,
      voteAccepted: false,
      voteError: false,
      coords: [0, 0],
      settings: {
        apiKey: "4f77a55a-eec1-4f77-961f-42cd4e50b13c",
        lang: "ru_RU",
        coordorder: "latlong",
        version: "2.1",
      },
      icon: {
        layout: "default#imageWithContent",
        imageHref: "/static/images/map-red-location.png",
        imageSize: [32, 36],
        imageOffset: [-18, -18],
      },
    };
  },
  mounted() {
    let address = this.communications.find((item) => item.type.code === this.$store.state._types.ADDRESS);
    if (address) {
      loadYmap({
        ...this.settings,
      }).then(() => {
        window.ymaps.ready(() => {
          window.ymaps.geocode(address).then((res) => {
            this.coords = res.geoObjects.get(0).geometry.getCoordinates();
            this.mapReady = true;
          });
        });
      });
    }
  },
  computed: {
    hospital() {
      return this.$store.state.hospital;
    },
  },
  components: {
    SocialsComponent,
    yandexMap,
    ymapMarker,
  },
};
</script>

<style lang="stylus">
.shop-contacts {
  display flex
  flex-direction column
  align-items center
  padding 0 15px

  &__container {
    width 100%
    max-width var(--main_width)
  }

  &__inner {
    margin 0 auto
    display grid
    grid-template-columns 746fr 526fr
    grid-gap 24px
    +below(640px) {
      grid-template-columns 1fr
    }
  }

  &__info {
    display flex
    flex-direction column
  }

  &__list {
    display flex
    flex-direction column
    gap 24px
  }

  &__item {
    display flex
    flex-direction column
    align-items flex-start
    gap 4px

    &-title {
      font-weight: 500;
      font-size: 0.875em
      line-height: 24px;
      color: var(--gray-300)
    }

    &-description {
      color var(--gray-700)
      transition var(--transition)

      a.&:hover {
        color var(--link)
      }
    }
  }

  &__socials {
    a {
      &:hover .icon svg path {
        fill: var(--link)
      }

      .icon {
        svg path {
          fill var(--blue-900)
        }
      }
    }
  }

  &__map {
    overflow hidden

    .ymap-container {
      width 100%
      height 500px
    }
  }
}
</style>
