<template>
  <div class="breadcrumbs">
    <ul class="breadcrumbs__container">
      <li class="breadcrumbs__button">
        <button type="button" class="btn btn--sm btn--hollow-dark" @click="$router.go(-1)">
          <RemixIconComponent category="System" name="arrow-left-line" />
          <span>Назад</span>
        </button>
      </li>
      <li>
        <router-link :to="{ name: 'home' }">
          <span>Главная</span>
          <RemixIconComponent category="System" name="arrow-right-s-line" />
        </router-link>
      </li>
      <li v-for="(link, i) in links" :key="i">
        <router-link :to="link.route" v-if="link.route">
          <span>{{ link.title }}</span>
          <RemixIconComponent category="System" name="arrow-right-s-line" />
        </router-link>
        <span v-else>{{ link.title }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
import RemixIconComponent from "components/RemixIconComponent.vue";

export default {
  name: "BreadcrumbsComponent",
  props: {
    links: Array,
  },
  components: { RemixIconComponent },
};
</script>

<style lang="stylus">
.breadcrumbs {
  display flex
  padding 0 15px
  justify-content center
  align-items center

  &__button {
    margin-right 16px
  }

  &__container {
    display flex
    align-items flex-start
    justify-content flex-start
    flex-wrap wrap
    max-width var(--main_width)
    width 100%
    +below(860px) {
      align-items center
    }

    li {
      display flex
      align-items center

      a {
        padding 8px 0
        font-weight: normal;
        font-size: 0.875em;
        line-height: 20px;
        display: flex;
        align-items: center;
        color: var(--gray-100);
        transition var(--transition)
        +below(860px) {
          padding 2px 0
        }

        &:hover {
          color var(--gray-500)
        }

        .icon {
          width 12px
          height 12px

          svg path {
            fill var(--gray-100)
          }
        }
      }

      > span {
        padding 8px 0
        font-weight: normal;
        font-size: 0.875em;
        line-height: 20px;
        display: flex;
        align-items: center;
        color: var(--gray-500);
      }
    }
  }

  .btn {
    flex-shrink 0
  }
}
</style>
