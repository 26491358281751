<template>
  <div class="reviews-stat">
    <div class="reviews-stat__container">
      <template v-if="reviews && reviews.length">
        <div class="reviews-stat__header">
          <div class="reviews-stat__average">{{ averageReviewsMark }}</div>
          <div class="reviews-stat__title">
            <span>
              На основании
              {{ reviews.length }} {{ reviews.length | plural_word("отзыва", "отзыва", "отзывов") }} отзывов
            </span>
            <div class="reviews-stat__stars">
              <RemixIconComponent
                class="reviews-stat__star"
                :class="{ 'reviews-stat__star--filled': Math.floor(averageReviewsMark) >= i }"
                category="System"
                name="star-fill"
                v-for="i in 5"
                :key="i"
              />
            </div>
          </div>
        </div>
        <ul class="reviews-stat__body">
          <li v-for="i in marks" :key="i">
            <span>{{ i }}</span>
            <span class="reviews-stat__loader">
              <span class="reviews-stat__loader-stripe" :style="`width: ${getPercentOfMarks(i)}%`"></span>
            </span>
            <span>{{ getPercentOfMarks(i) }}%</span>
          </li>
        </ul>
      </template>
      <p v-else>Отзывы отсутствуют</p>
    </div>
    <button @click="$emit('show')" type="button" class="btn btn--lg btn--main">Написать отзыв</button>
  </div>
</template>

<script>
import RemixIconComponent from "components/RemixIconComponent.vue";

export default {
  name: "ReviewsStatComponent",
  props: {
    reviews: Array,
  },
  data() {
    return {
      marks: [5, 4, 3, 2, 1],
    };
  },
  methods: {
    getPercentOfMarks(mark) {
      let filteredListLength = this.reviews
        .filter((r) => parseInt(r.mark) === parseInt(mark))
        .map((r) => r.mark).length;
      return Math.round((filteredListLength / this.reviews.length) * 100);
    },
  },
  computed: {
    averageReviewsMark() {
      if (this.reviews && this.reviews.length) {
        let length = this.reviews.length;
        return (this.reviews.map((r) => parseInt(r.mark)).reduce((a, b) => a + b, 0) / length).toFixed(1);
      }
      return 0;
    },
  },
  components: { RemixIconComponent },
};
</script>

<style lang="stylus">
.reviews-stat {
  display grid
  grid-gap 8px

  &__container {
    background: var(--blue-200);
    border-radius: var(--small_radius);
    padding 16px
    display grid
    grid-gap 16px
  }

  &__header {
    display flex
    align-items center
    justify-content flex-start
    gap 24px
  }

  &__average {
    background: var(--blue-100);
    border-radius: var(--small_radius);
    padding 12px
    font-weight: normal;
    font-size: 3em;
    line-height: 67px;
    display: flex;
    align-items: center;
    color: var(--gray-700);
  }

  &__title {
    display grid
    grid-gap 4px
    font-weight: normal;
    font-size: 0.875em;
    line-height: 20px;
    color: var(--gray-700);
  }

  &__stars {
    display inline-flex
    align-items center
    justify-content flex-start
    gap 2px
  }

  &__star {
    width 20px
    height 20px

    &:not(&--filled) {
      svg path {
        fill transparent
        stroke var(--yellow)
      }
    }

    &--filled {
      svg path {
        fill var(--yellow)
      }
    }
  }

  &__body {
    display grid
    width 100%

    li {
      display grid
      align-items center
      grid-gap 16px
      grid-template-columns auto 1fr auto

      > span:first-child {
        width 25px
      }

      > span:last-child {
        width 45px
      }

      > span:first-child,
      > span:last-child {
        padding 8px
        font-weight: normal;
        font-size: 0.875em;
        line-height: 20px;
        display: flex;
        align-items: center;
        text-align center
        color: var(--gray-900);
      }
    }
  }

  &__loader {
    width 100%
    height 4px
    background: var(--white);
    position relative
  }

  &__loader-stripe {
    absolute left top
    height 100%
    background var(--accent)
  }

  > .btn {
    width 100%
    justify-content center
  }
}
</style>
