<template>
  <div class="default-modal rc-modal">
    <div class="default-modal__header">
      <h3 class="default-modal__title">Написать отзыв</h3>
      <button type="button" class="default-modal__close" @click="$emit('close')">
        <RemixIconComponent category="System" name="close-line" />
      </button>
    </div>
    <div class="default-modal__body rc-modal__body">
      <div v-if="data && data.title" class="rc-modal__product">
        <span>Отзыв на:</span>
        <template v-if="data.brand && data.brand.title">{{ data.brand.title }} /</template>
        {{ data.title }}
      </div>
      <InputComponent
        v-model.trim="form.name.value"
        :error="form.name.error"
        title="Имя"
        :disabled="anonymous"
      />
      <CheckboxComponent :checked="anonymous" @change="anonymous = !anonymous">
        <span>Анонимно</span>
      </CheckboxComponent>
      <div class="rc-modal__mark">
        <span class="rc-modal__mark-title">
          <template v-if="form.mark.value"> Оценка: {{ getMarkTitle() }} </template>
          <template v-else>Оценка</template>
        </span>
        <div class="rc-modal__stars">
          <button
            @mouseover="hoverMark = i"
            @mouseleave="hoverMark = null"
            @click="form.mark.value = i"
            v-for="i in 5"
            :key="i"
            class="btn"
          >
            <RemixIconComponent
              class="rc-modal__star"
              :class="{
                'rc-modal__star--filled': hoverMark >= i || form.mark.value >= i,
                'rc-modal__star--opacity': hoverMark && i > hoverMark && i <= form.mark.value,
              }"
              category="System"
              name="star-fill"
            />
          </button>
        </div>
      </div>
      <TextareaComponent
        v-model="form.description.value"
        title="Отзыв"
        placeholder="Текст отзыва"
        :subtitle="form.description.message"
        :error="form.description.error"
      />
    </div>
    <div class="default-modal__footer rc-modal__footer">
      <button @click="reviewCreate" type="button" class="btn btn--md btn--main">
        <FileIconComponent v-if="loading" name="loading" />
        <template v-else>Отправить</template>
      </button>
    </div>
  </div>
</template>

<script>
import RemixIconComponent from "components/RemixIconComponent.vue";
import InputComponent from "components/inputs/InputComponent.vue";
import FileIconComponent from "components/FileIconComponent.vue";
import TextareaComponent from "components/inputs/TextareaComponent.vue";
import CheckboxComponent from "components/inputs/CheckboxComponent.vue";
import REVIEW_CREATE from "gql/mutations/ReviewCreate.graphql";

export default {
  name: "ReviewCreateModal",
  props: {
    data: Object,
    reviewable_type: String,
  },
  data() {
    return {
      hoverMark: null,
      anonymous: false,
      loading: false,
      form: {
        name: {
          value: undefined,
          error: undefined,
          message: undefined,
        },
        description: {
          value: undefined,
          error: undefined,
          message: undefined,
        },
        mark: {
          value: undefined,
          error: undefined,
          message: undefined,
        },
      },
    };
  },
  computed: {
    user() {
      return this.$store.state.auth.user;
    },
  },
  mounted() {
    if (this.user && this.user.name) {
      this.form.name.value = this.user.name;
    }
  },
  methods: {
    reviewCreate() {
      if (!this.loading) {
        this.loading = true;
        this.$apollo.provider.defaultClient
          .mutate({
            mutation: REVIEW_CREATE,
            variables: {
              name: this.anonymous ? undefined : this.form.name.value,
              description: this.form.description.value,
              reviewable_id: this.data?.id,
              mark: this.form.mark.value,
              reviewable_type: this.reviewable_type,
            },
            context: {
              headers: {
                Authorization: "Bearer " + this.$store.state.apollo_token,
              },
            },
          })
          .then(({ data }) => {
            this.$emit("close");
            this.$notify({
              title: "Отправлено",
              text: data.ReviewCreate.message,
              duration: 5000,
              speed: 200,
              type: "success",
            });
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
    getMarkTitle() {
      switch (this.form.mark.value) {
        case 1: {
          return "Ужасно";
        }
        case 2: {
          return "Плохо";
        }
        case 3: {
          return "Удовлетворительно";
        }
        case 4: {
          return "Хорошо";
        }
        case 5: {
          return "Отлично";
        }
      }
    },
  },
  components: { CheckboxComponent, TextareaComponent, FileIconComponent, InputComponent, RemixIconComponent },
};
</script>

<style lang="stylus">
.rc-modal {
  width 100%
  max-width 460px

  &__body {
    align-items stretch
    gap 12px

    .textarea__field {
      height 150px
    }
  }

  &__body .input,
  &__footer .btn {
    width 100%
  }

  &__product {
    display flex
    gap 8px

    span {
      flex-shrink 0
    }
  }

  &__mark {
    display flex
    flex-direction column
    gap 4px

    &-title {
      font-weight: normal;
      font-size: 0.875em;
      line-height: 20px;
      color: var(--body-color-muted);
    }

    .btn:hover .icon svg path {
      fill var(--yellow)
    }
  }

  &__stars {
    display inline-flex
    align-items center
    justify-content flex-start
    margin 0 -4px

    .btn {
      padding 0 4px
    }
  }

  &__star {
    width 24px
    height 24px

    &:not(&--filled) {
      svg path {
        fill transparent
        stroke var(--yellow)
      }
    }

    &--filled {
      svg path {
        fill var(--yellow)
      }
    }

    &--opacity {
      opacity 0.5
    }
  }
}
</style>
