<template>
  <div class="review">
    <img
      :src="data.head_img | image($store.state._env.MEDIA_ENDPOINT)"
      :alt="data.head_img | image_alt"
      class="review__image"
      loading="lazy"
    />
    <div class="review__top">
      <span class="review__name">
        <template v-if="data.name || data.surname"> {{ data.name }} {{ data.surname }} </template>
        <template v-else>Аноним</template>
      </span>
      <time class="review__date" v-if="data.created_at" :datetime="data.created_at | robotDate">
        {{ data.created_at | humanDateTime }}
      </time>
    </div>
    <div class="review__stars">
      <RemixIconComponent
        class="review__star"
        :class="{ 'review__star--filled': data.mark >= i }"
        category="System"
        name="star-fill"
        v-for="i in 5"
        :key="i"
      />
    </div>
    <p class="review__description">{{ data.description }}</p>
  </div>
</template>

<script>
import RemixIconComponent from "components/RemixIconComponent.vue";

export default {
  name: "ReviewComponent",
  components: { RemixIconComponent },
  props: {
    data: Object,
  },
};
</script>

<style lang="stylus">
.review {
  padding 0 0 0 60px
  position relative

  &__image {
    absolute left top
    width 48px
    height 48px
    border-radius 50px
    object-fit cover
    object-position center
  }

  &__top {
    display flex
    align-items flex-start
    justify-content space-between
    flex-wrap wrap-reverse
  }

  &__name {
    font-weight: 500;
    font-size: 0.875em;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: var(--gray-700);
    margin-bottom 4px
  }

  &__date {
    font-weight: 400;
    font-size: 0.875em;
    line-height: 20px;
    color: var(--gray-200)
  }

  &__stars {
    display inline-flex
    align-items center
    justify-content flex-start
    margin-bottom 16px
  }

  &__star {
    width 20px
    height 20px

    &:not(&--filled) {
      svg path {
        fill transparent
        stroke var(--yellow)
      }
    }

    &--filled {
      svg path {
        fill var(--yellow)
      }
    }
  }

  &__description {
    font-weight: normal;
    font-size: 0.875em;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: var(--gray-700);
  }
}
</style>
