<template>
  <div class="shop-about">
    <div class="shop-about__content">
      <div class="shop-about__img">
        <ImgComponent :head_img="data.head_img" width="310" height="290" />
      </div>
      <div class="shop-about__info">
        <h1 class="shop-about__title">{{ data.title }}</h1>
        <div class="shop-about__stars-container">
          <div class="shop-about__stars">
            <RemixIconComponent
              class="shop-about__star"
              :class="{ 'shop-about__star--filled': averageReviewsMark >= i }"
              category="System"
              name="star-fill"
              v-for="i in 5"
              :key="i"
            />
          </div>
          <span class="shop-about__reviews-count">({{ data.reviews.length }} оценок)</span>
        </div>
        <div v-if="data.description" class="shop-about__description">
          <EditorJSComponent :text="data.description" />
        </div>
        <router-link
          :to="{ name: 'catalog', query: { shop_id: data.id, page_title: data.title } }"
          class="btn btn--lg btn--main"
        >
          Перейти к товарам бренда
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import RemixIconComponent from "components/RemixIconComponent.vue";
import EditorJSComponent from "components/EditorJSComponent.vue";

export default {
  name: "ShopAboutComponent",
  components: { EditorJSComponent, RemixIconComponent },
  props: {
    data: Object,
  },
  computed: {
    averageReviewsMark() {
      if (this.data && this.data.reviews && this.data.reviews.length) {
        let length = this.data.reviews.length;
        return Math.floor(this.data.reviews.map((r) => parseInt(r.mark)).reduce((a, b) => a + b, 0) / length);
      }
      return 0;
    },
  },
};
</script>

<style lang="stylus">
.shop-about {
  display flex
  flex-direction column
  align-items center
  width 100%
  padding 48px 15px
  background var(--blue-200)
  +below(768px) {
    padding 24px 15px
  }
  +below(420px) {
    padding 15px
  }

  &__content {
    width 100%
    max-width var(--main_width)
    display flex
    gap 24px
    +below(768px) {
      flex-direction column
    }
  }

  &__img {
    width 310px
    height 290px
    flex-shrink 0
    +below(420px) {
      width 100%
      height 310px
    }

    img {
      width 100%
      height 100%
      object-fit cover
      object-position center
      border-radius var(--main_radius)
    }
  }

  &__info {
    display flex
    flex-direction column
    align-items flex-start
    gap 16px

    .btn {
      +below(420px) {
        width 100%
      }
    }
  }

  &__title {
    font-weight: 400;
    font-size: 2.25em
    line-height: 50px;
    margin 0
  }

  &__stars {
    display flex

    &-container {
      display flex
      align-items center
      justify-content flex-start
      gap 8px
    }
  }

  &__reviews-count {
    font-size: 0.875em
    line-height: 20px;
    color: var(--blue-900)
  }

  &__star {
    width 24px
    height 24px

    &:not(&--filled) {
      svg path {
        fill transparent
        stroke var(--yellow)
      }
    }

    &--filled {
      svg path {
        fill var(--yellow)
      }
    }
  }

  &__description {
    font-size: 0.875em
    line-height: 20px;
    color var(--gray-700)
  }
}
</style>
