<template>
  <div class="shop-page page">
    <BreadcrumbsComponent @back.prevent :links="links" />
    <ShopAboutComponent :data="data" />
    <ShopCategoriesComponent :categories="data.categories" />
    <SliderComponent
      title="Новинки"
      :items="data.new_products"
      :slider-options="sliderOptions"
      v-slot="{ item }"
    >
      <ProductCardComponent :data="item" />
    </SliderComponent>
    <SliderComponent
      title="Популярные"
      :items="data.popular_products"
      :slider-options="sliderOptions"
      v-slot="{ item }"
    >
      <ProductCardComponent :data="item" />
    </SliderComponent>
    <SliderComponent
      title="Акции"
      buttonTitle="Все акции"
      :route="{ name: 'shares' }"
      :slider-options="promoSliderOptions"
      :items="[]"
      v-slot="{ item }"
    >
      <PromoComponent :data="item" />
    </SliderComponent>
    <SliderComponent
      title="Выгодные предложения"
      :items="good_deal_products.data"
      :slider-options="sliderOptions"
      v-slot="{ item }"
    >
      <ProductCardComponent :data="item" />
    </SliderComponent>
    <ReviewsComponent
      class="shop-page__mb-64"
      :reviews="data.reviews"
      :data="data"
      reviewable_type="App\Models\Shops"
    />
    <NumbersComponent :numbers="numbers" />
    <ShopFeedbackComponent />
    <ShopContactsComponent :communications="data.communications" />
  </div>
</template>

<script>
import ShopAboutComponent from "./components/ShopAboutComponent.vue";
import ShopCategoriesComponent from "./components/ShopCategoriesComponent.vue";
import NumbersComponent from "components/NumbersComponent.vue";
import ShopFeedbackComponent from "./components/ShopFeedbackComponent.vue";
import ShopContactsComponent from "./components/ShopContactsComponent.vue";
import SliderComponent from "components/SliderComponent.vue";
import ProductCardComponent from "components/product/ProductCardComponent.vue";
import BreadcrumbsComponent from "components/BreadcrumbsComponent.vue";
import ReviewsComponent from "components/reviews/ReviewsComponent.vue";
import PromoComponent from "components/PromoComponent.vue";
import SHOP_PAGE from "@/graphql/pages/ShopPage.graphql";

export default {
  name: "ProductPage",
  async asyncData({ apollo, store, route }) {
    await apollo.defaultClient
      .query({
        query: SHOP_PAGE,
        variables: {
          id: parseInt(route.params.id),
        },
      })
      .then(({ data }) => {
        store.state.shop_page.shop = data.shop;
        store.state.shop_page.good_deal_products = data.good_deal_products;
        store.state.categories = data.categories;
        store.state.pages = data.pages;
        store.state.marketplace = data.marketplace;
        if (data.exchange_rates) store.state.exchange_rates = data.exchange_rates;
      });
  },
  data() {
    return {
      sliderOptions: {
        slidesPerView: 1.5,
        spaceBetween: 15,
        pagination: false,
        clickable: false,
        watchSlidesProgress: true,
        breakpoints: {
          460: {
            slidesPerView: 1.8,
            spaceBetween: 15,
          },
          540: {
            slidesPerView: 2.2,
            spaceBetween: 15,
          },
          740: {
            slidesPerView: 2.9,
            spaceBetween: 15,
          },
          860: {
            slidesPerView: 3.5,
            spaceBetween: 15,
          },
          1024: {
            slidesPerView: 4,
            spaceBetween: 24,
          },
        },
      },
      promoSliderOptions: {
        slidesPerView: 1.8,
        spaceBetween: 24,
        pagination: false,
        clickable: false,
        breakpoints: {
          480: {
            slidesPerView: 2.8,
            spaceBetween: 24,
          },
          640: {
            slidesPerView: 3.5,
            spaceBetween: 24,
          },
          860: {
            slidesPerView: 4,
            spaceBetween: 24,
          },
          940: {
            slidesPerView: 5,
            spaceBetween: 24,
          },
          1200: {
            slidesPerView: 6,
            spaceBetween: 24,
          },
        },
      },
    };
  },
  computed: {
    data() {
      return this.$store.state.shop_page.shop;
    },
    good_deal_products() {
      return this.$store.state.shop_page.good_deal_products;
    },
    // main_shares() {
    //   return this.$store.state.shop_page.main_shares || [];
    // },
    links() {
      return [{ title: "Бренды", route: { name: "shops" } }, { title: this.data.title }];
    },
    numbers() {
      return [
        {
          title: "Товаров",
          value: this.data.count_products || 0,
        },
      ];
    },
  },
  components: {
    ShopAboutComponent,
    ShopCategoriesComponent,
    NumbersComponent,
    ShopFeedbackComponent,
    ShopContactsComponent,
    ReviewsComponent,
    BreadcrumbsComponent,
    ProductCardComponent,
    SliderComponent,
    PromoComponent,
  },
  metaInfo() {
    return {
      title: this.data.meta_title,
      description: this.data.meta_description,
      keywords: this.data.meta_keywords,
    };
  },
};
</script>

<style lang="stylus">
.shop-page {
  display grid
  grid-gap 48px
  padding 32px 0 48px
  +below(860px) {
    padding 15px 0 32px
  }

  &__container {
    display flex
    align-items center
    justify-content center
    padding 0 15px
  }

  &__mb-32 {
    margin-bottom 32px
    +below(860px) {
      margin-bottom 16px
    }
  }

  &__mb-48 {
    margin-bottom 48px
    +below(860px) {
      margin-bottom 24px
    }
  }

  &__mb-64 {
    margin-bottom 64px
    +below(860px) {
      margin-bottom 32px
    }
  }

  .form__row .input, .textarea {
    width 100%
  }
}
</style>
