<template>
  <div class="shop-categories">
    <ul class="shop-categories__list">
      <li class="shop-categories__item" v-for="(item, i) in categories" :key="i">
        <router-link
          class="shop-categories__link"
          :to="{ name: 'catalog', params: { id: item.id }, query: { page_title: item.title } }"
        >
          {{ item.title }}
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "ShopCategoriesComponent",
  props: {
    categories: Array,
  },
};
</script>

<style lang="stylus">
.shop-categories {
  padding 0 15px
  display flex
  flex-direction column
  align-items center

  &__list {
    display flex
    gap 8px
    width 100%
    max-width var(--main_width)
    overflow auto
  }

  &__item {
    flex-shrink 0
  }

  &__link {
    display flex
    align-items center
    justify-content center
    padding 24px
    font-size: 0.875em
    line-height: 20px;
    text-align center
    color: var(--gray-500)
    background: var(--white)
    border: 1px solid var(--blue-300)
    border-radius: var(--small_radius)

    &:hover {
      border-color var(--link)
    }
  }
}
</style>
